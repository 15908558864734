import {Component} from '@angular/core'
import { Router } from '@angular/router';
// import { EventService } from './shared/index';

@Component({
    templateUrl:'add-new-client.component.html',
    styles:[`
    em {float:right;color:#E05C65;padding-left:10px;}
    .error input{background-color:#E3C3C5}
    .error ::-webkit-input-placeholder {color:#999}
    .error ::-moz-placeholder {color:#999}
    .error :-moz-placeholder {color:#999}
    .error :ms-input-placeholder {color:#999}
    `]

})

export class AddNewClientComponent{
    newEvent
    isDirty: boolean = true
  
    // constructor(private router:Router, private eventService:EventService){

    // }
    constructor(private router:Router){

    }


    saveEvent(formValues){
        // this.eventService.saveEvent(formValues)
        // this.isDirty = false
        // this.router.navigate(['/events']);
        //this.eventService.saveEvent(formValues).subscribe(()=> {
            this.isDirty = false
            this.router.navigate(['/home']);
        //})
    }

    cancel(){
        this.router.navigate(['/home']);
    }
}