import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class DocTreeService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    addItem(form){
        return this.http.post(`${this.baseUrl}` + '/Document/addItem', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    getRoot(){
        return this.http.get(`${this.baseUrl}` + '/Document/getRoot',{observe:'response',withCredentials:true})
    }

    deleteTreeElement(id){
        return this.http.delete(`${this.baseUrl}` + '/Document/deleteItems?childId=' + id,{ headers: { 'Content-Type': 'application/json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    editItem(form){
        return this.http.put(`${this.baseUrl}` + '/Document/updateItem',form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

}