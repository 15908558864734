import {Injectable} from '@angular/core'
import { IUser } from './user.model'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DecodeTokenService } from './decodetoken.service';

@Injectable()
export class AuthService implements CanActivate{
    //currentUser:IUser
    currentUser
    isLoggedIn: boolean = false
    POSTResponse: Array<any> = []
    baseUrl: string = environment.backend.baseURL
    constructor(
        private http:HttpClient,
        private router:Router,
        private decodeToken:DecodeTokenService
    ){}

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Observable<boolean> | boolean{
            return this.decodeToken.accessGranted(route.routeConfig.path)
    }

    loginUser(userName: string, Password: string){
        let loginInfo = { Email: userName, Password: Password}
    
        return this.http.post(`${this.baseUrl}` + '/Account/authenticate', loginInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    this.currentUser = data
                    this.isLoggedIn = true
                    return data.body
                })
            );
    }     

    isAuthenticated(){
        return !!this.currentUser;
    }

    checkAuthenticationStatus(){
        // this.http.get('/api/currentIdentity')
        //     .pipe(tap(data =>{
        //         if(data instanceof Object){
        //             this.currentUser = <IUser>data
        //             this.isLoggedIn = true
        //         }
        //     }))
        //     .subscribe()
            
    }

    updateCurrentUser(firstName:string,lastName:string){
        // this.currentUser.firstName = firstName
        // this.currentUser.lastName = lastName

        // let options = {headers: new HttpHeaders({'Content-Type':'application/json'})}
        // return this.http.put(`/api/users/${this.currentUser.id}`,this.currentUser,options)

    }

    logout(){
        // this.currentUser = undefined
        // let options = {headers: new HttpHeaders({'Content-Type':'application/json'})}
        // //add ${this.currentUser.id} to sent info about user to logout
        // this.isLoggedIn = false
        // return this.http.post('/api/logout',{},options)
    }

    resetPsw(user){
        let loginInfo = {}
        return this.http.post(`${this.baseUrl}` + '/Account/reset?email=' + user, loginInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
        .pipe(
            map(data => {
                return {"status": 200,"statusText": "Wysłano","success":data.ok, "message": "Wysłano", "value": data};
            })
        );
    }
}