import { Injectable } from "@angular/core";
import * as jwt_decode from 'jwt-decode';
import { TokenService } from '../tokenservice/tokenservice.service';

@Injectable()

export class DecodeTokenService {
    flag:boolean
    webBoxes:any
    userToken:any
    userAccessBoxAccess:any
    userAccess:any
    pageList =[]
    accessExist = []

    constructor(
        private tokenService:TokenService
    ){}
    decodeToken(tokenSent:string){
        var token = tokenSent;
        var decoded = jwt_decode(token); 
        return decoded         
    }

    accessGranted(accessName:string){
        this.userToken = this.tokenService.getSubjectToken()
        this.userAccessBoxAccess = this.decodeToken(this.userToken)
        this.userAccess= JSON.parse(this.userAccessBoxAccess.BoxAccess)
        this.pageList = []
        for(var key in this.userAccess){
            this.pageList.push(this.userAccess[key].WebBoxName)
        }
        return this.pageList.filter(itm => itm === accessName).length !== 0
    }
}