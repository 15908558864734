import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class HolidayService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    addLeaveRequest(form){
        return this.http.post(`${this.baseUrl}` + '/Holiday/addLeaveRequest', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    getUserLeaveRequests(){
        return this.http.get(`${this.baseUrl}` + '/Holiday/getUserLeaveRequests',{observe:'response',withCredentials:true})
    }

    getAllLeaveRequest(leaveType){
        return this.http.get(`${this.baseUrl}` + '/Holiday/getAllLeaveRequests?leaveRequestStatus=' + leaveType,{observe:'response',withCredentials:true})
    }

    deleteLeaveRequest(leaveId){
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(leaveId),
        };
        return this.http.delete(`${this.baseUrl}` + '/Holiday/deleteLeaveRequest',options)
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    getUserUsedHolidays(user,year,type,status){
        return this.http.get(`${this.baseUrl}` + '/Holiday/getUserUsedHolidays?userId=' + user + '&year=' + year + '&leaveRequestType=' + type + '&leaveRequestStatus=' + status,{observe:'response',withCredentials:true})
    }

    changeLeaveRequestStatus(form){
        return this.http.put(`${this.baseUrl}` + '/Holiday/changeLeaveRequestStatus', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
        .pipe(
            map(data => {
                return data
            })
        );
    }
}