import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable()

export class NewsPanelService {
    baseUrl: string = environment.backend.baseURL

    change = new Subject<number>()

    constructor(private http:HttpClient){}

    postNews(title: string, newsBody: string,flag:boolean,date:string, teams){
        let newsInfo = { 
                Subject: title, 
                Text: newsBody, 
                isPriority:flag,
                ExpirationDate:date,
                //AddDate:adddate,
                //AddedByUserId:user
                TeamsId: teams
            }
    
        return this.http.post(`${this.baseUrl}` + '/message/addNewMessage', newsInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text', observe:'response'})
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
    
    getActiveMsgForUser(){
        return this.http.get(`${this.baseUrl}` + '/Message/getActiveMsgForUser',{observe:'response',withCredentials:true});
    }

    getAllValidMsgForUser(){
        return this.http.get(`${this.baseUrl}` + '/Message/getAllValidMsgForUser',{observe:'response',withCredentials:true});
    }    

    getAllMessages(){
        return this.http.get(`${this.baseUrl}` +'/Message/getAllMsgs',{observe:'response',withCredentials:true})
    }

    markAsRead(user:any,msg:any){
        let markInfo = { 
            InstantMessageId: msg,
            userID: user 
        }

        return this.http.put(`${this.baseUrl}` + '/Message/markAsRead', markInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }

    emitBadgeValue(value: number){
        this.change.next(value);
    }

    updateNews(form){
        return this.http.put(`${this.baseUrl}` + '/Message/editMessage', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );        
    }

    cancelNewsAdm(mesId){
        let mesInfo = {
            InstantMessageId: mesId
        }
        return this.http.put(`${this.baseUrl}` + '/Message/dectivateMessage', mesInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );         
    }

}