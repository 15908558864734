import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';


@Injectable()

export class PermissionsService {
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    getAccessLevels(){
        return this.http.get(`${this.baseUrl}` + '/Access/getAccessLevels' ,{observe:'response',withCredentials:true}); 
    }

    getWebBoxes(level){
        return this.http.get(`${this.baseUrl}` + '/Access/getWebBoxes?accessLevelId=' + level ,{observe:'response',withCredentials:true});        
    }

    updateAccessLevel(accessInfo){
        return this.http.put(`${this.baseUrl}` + '/Access/updateAccessLevel', accessInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }
}