import {Component, ViewEncapsulation} from '@angular/core'

@Component ({
    selector: 'dictionaries',
    templateUrl: './dictionaries.component.html',
    styles:[`
        /*.mat-expansion-panel {background-color:transparent!important}*/
    `],
    encapsulation: ViewEncapsulation.None
})

export class DictionariesComponent {

}