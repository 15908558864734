import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../tokenservice/tokenservice.service';

@Injectable()



export class JwtInterceptor implements HttpInterceptor{
    authToken ={}
    constructor(private tokenService:TokenService){}

    intercept(request:HttpRequest<any>,next: HttpHandler):Observable<HttpEvent<any>>{
        this.authToken = this.tokenService.getSubjectToken()

        request = request.clone({
            setHeaders:{
                Authorization: `Bearer ${this.tokenService.getSubjectToken()}`
            }
        })
        return next.handle(request)
    }
}