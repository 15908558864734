import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class InvoiceService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    addInvoice(form){
        return this.http.post(`${this.baseUrl}` + '/Invoice/addInvoice', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    getInvoices(form){
        let employee = form.employee
        let dateFrom = form.dateFrom
        let dateTo = form.dateTo
        return this.http.get(`${this.baseUrl}` + '/Invoice/getInvoices?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&teamId=' + employee,{observe:'response',withCredentials:true})
    }

    deleteInvoice(invoiceId){
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(invoiceId),
        };
        return this.http.delete(`${this.baseUrl}` + '/Invoice/deleteInvoice',options)
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
}