import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class CreateUserService {
    baseUrl = environment.backend.baseURL
    constructor(private http:HttpClient){}

    // getLocationList(){
    //     return this.http.get(`${this.baseUrl}` + "/Users/getLocations",{observe:'response',withCredentials:true})
    // }

    // getTeamList(){
    //     return this.http.get(`${this.baseUrl}` + "/Users/getTeams",{observe:'response',withCredentials:true})
    // }

    // getPositionList(){
    //     return this.http.get(`${this.baseUrl}` + "/Users/getJobTitles",{observe:'response',withCredentials:true})
    // }
    // getLevelList(){
    //     return this.http.get(`${this.baseUrl}` + "/Access/getAccessLevels",{observe:'response',withCredentials:true})
    // }

    createUser(userDetails){
        let loginInfo = userDetails
    
        return this.http.post(`${this.baseUrl}` + '/Users/register', loginInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data;
                })
            );       
    }
}