import { Component, ViewEncapsulation } from '@angular/core';
import { slideInAnimation } from './animations/animations';
import { AuthService } from './services/authservice/auth.service';
import {RouterOutlet} from '@angular/router'
import { CommunicationService } from './services/communication/communication.service';
import { TokenService } from './services/tokenservice/tokenservice.service';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[
    slideInAnimation
  ],
  encapsulation:ViewEncapsulation.None
})
export class AppComponent {
  showLoader = false
  subscription
  versionNumber:any
  constructor(private auth:AuthService,private tokenService:TokenService, private communicationService:CommunicationService){
    communicationService.changeEmitted$.subscribe(data => {
      if(this.showLoader){
        this.showLoader = false
      } else {
        this.showLoader = true;
      }
      
    })

  
    this.subscription = this.tokenService.getSubjectToken()

  }

  ngOnInit(){
    //this.versionNumber = environment.version
    if(localStorage.getItem("userToken") !== ""){
      this.tokenService.saveSubjectGlobally(localStorage.getItem("userToken"))
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
