import { Component, OnInit, ViewChild, Input, OnDestroy, Inject, ViewEncapsulation} from "@angular/core";
import { MatMenu, MatMenuTrigger, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from '../../../../../node_modules/@angular/router';
import { DocTreeService } from '../../../services/doctree/doctree.service';
import { takeUntil, catchError } from '../../../../../node_modules/rxjs/operators';
import { Subject, empty } from '../../../../../node_modules/rxjs';
import { DialogData } from '../../employeelist/employee-list.component';
import { FormControl, Validators } from '../../../../../node_modules/@angular/forms';
import { CommunicationService } from '../../../services/communication/communication.service';

@Component({
  selector: "app-menu-panel",
  templateUrl: "./menu-panel.component.html",
  styles:[`
    .customMatMenuClass{
        background:#3a3f44;
    }
    .customMatMenuClass-Context{
        
    }
    div div button.service-class{
        max-width:280px!important;
    }
    .mat-menu-panel{
        min-height:0px!important;
        background:#212121!important;
    }
    .mat-menu-content{
        padding:0!important;
    }
   

  `],
  encapsulation:ViewEncapsulation.None
})
export class MenuPanelComponent implements OnInit,OnDestroy {
  @ViewChild("menu", {static: true}) menu: MatMenu;
  @Input() items: {name: string, children: string[]}[];
  private ngUnsubscribe = new Subject()
  treeID:any

  constructor(
    public router:Router,
    public doctree:DocTreeService,
    public snackBar:MatSnackBar,
    public dialog:MatDialog,
    public communicationService: CommunicationService
  ) {}

  ngOnInit() {}
  
  @ViewChild(MatMenuTrigger,{static: true})
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  onContextMenu(event: MouseEvent, item) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onContextMenuAction(item,id){
    switch(id){
        case 0:{
            this.openDialog(item.childID)
            break;
        }
        case 1:{

            break;
        }
        case 2:{
            this.deleteItems(item.childID)
            break;
        }
    }
  }

  deleteItems(id){
    this.doctree.deleteTreeElement(id)
    .pipe(
       catchError((err: any) => {
            console.log(err)
            this.snackBar.open(err.error, "X", {
                duration: 10000,
                panelClass: "bg-danger"
              });
            return empty()
        }),
        takeUntil(this.ngUnsubscribe)
    )
    .subscribe(resp =>{
        let respObj = JSON.parse(resp.body)
        let message = respObj.message
        let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
        if(respObj.isSuccess){
            this.communicationService.refreshRoot.emit(true)
        }
        this.snackBar.open(message, "X", {
            duration: respObj.isSuccess? 3000:10000,
            panelClass: messageClass
        });
        
    }) 
  }

  openDialog(id){
      const dialogRef = this.dialog.open(ContextMenuDialog,{
          width:'40rem',
          data:{
              childID:id
          }
      })
      
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

@Component({
    selector:'context-menu-dialog',
    templateUrl:'./context-menu-dialog.component.html'
})

export class ContextMenuDialog implements OnDestroy{
    descValue = new FormControl('',Validators.required)
    private ngUnsubscribe = new Subject()

    constructor(
        public dialogRef: MatDialogRef<ContextMenuDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private doctree:DocTreeService,
        private snackBar:MatSnackBar,
        private communicationService: CommunicationService
    ) {}

    addItem(val,id){
        let form = {
            "ChildName": val,
            "ParentId": id       
        }
        this.doctree.addItem(form)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                    });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
                if(respObj.isSuccess){
                    this.communicationService.refreshRoot.emit(true)
                    this.dialogRef.close()
                }
        });
    }

    addItemRef(){
        this.addItem(this.descValue.value,this.data.childID)
    }

    closeDialog(){
        this.dialogRef.close()
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
