import { Component, Input } from "@angular/core";
import { NewsPanelService } from '../../../services/newspanel/newspanel.service';
import { FormArray, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CommunicationService } from '../../../services/communication/communication.service';
import { MatSnackBar } from '@angular/material';
import { tap, catchError, map } from 'rxjs/operators';
import { empty } from 'rxjs';
import { SharedServicesService } from '../../../services/sharedservices/sharedservices.service';

// export interface MsgInterface {
//     instantMessageId:number,
//     heading: string,
//     subject: string,
//     text: string,
//     isPriority: string,
//     expirationDate: string,
//     addDate: string,
//     addedByUserId: number
// }

@Component({
    selector:'news-panel-admin-all',
    templateUrl:'./news-panel-admin-all.component.html'
})

export class NewsPanelAdminComponent {
    allMessages
    //controls:FormArray
    mouseoverSubject:boolean
    mouseoverSubjectCancel:boolean
    showIndex:any = -1;
    mouseoverText:boolean
    mouseoverTextCancel:boolean
    showIndexText:any = -1;
    dpnsaMinDate:Date = new Date;
    orgSubject:string
    priorityChange:number = -1;
    dateChange:number = -1;

    constructor(
        private getMessages:NewsPanelService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar,
        private sharedServices: SharedServicesService
    ){
        this.getMessages.getAllMessages().subscribe(resp =>{
            this.allMessages = resp.body
            this.allMessages.sort(function(a,b){return b.instantMessageId - a.instantMessageId})
            this.mouseoverSubject = false;
        })
    }

    ngOnInit(){       
    }

    newsOutDated(date){
        return this.sharedServices.dateLessThanCurrent(date)        
    }

    onNameChange(event){
    }

    updateMessage(form){

        this.communicationService.emitChange()
        this.getMessages.updateNews(form)
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                })
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                this.snackBar.open("Wiadomość zaktualizowana", "X", {
                    duration: 3000,
                    panelClass: "bg-success"
                });
            });
    }

    cancelNews(newsId){
        this.getMessages.cancelNewsAdm(newsId)
            .pipe(
               catchError((err: any) => {
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                })
            )
            .subscribe(resp =>{
                this.allMessages.find(item => item.instantMessageId === newsId).expirationDate = new Date();
                this.snackBar.open("Wiadomość anulowana", "X", {
                    duration: 3000,
                    panelClass: "bg-success"
                });
            });        
    }

    disableCancelBtn(date){ 
        return this.sharedServices.dateLessThanCurrent(date)
    }
}