import {Component, Input, Output,EventEmitter, OnDestroy} from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../services/authservice/auth.service'
import {Observable, empty, Subject} from 'rxjs';
import { RestApiService } from '../shared/rest-api.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { tap, catchError, map, takeUntil } from 'rxjs/operators';
import { CommunicationService } from '../services/communication/communication.service';
import { TokenService } from '../services/tokenservice/tokenservice.service';
import { EmailValidatorService } from '../services/emailValidator/emailValidator.service';
import { MatSnackBar } from '@angular/material';
import { ngIfAnimation } from '../animations/animations';
import { AccountService } from '../services/account/account.service';
import { FormGroup, FormControl, Validators } from '../../../node_modules/@angular/forms';




@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styles:[`
    em {float:right; color:#E05C65;padding-left:10px;}
    .password-tip {color:white;font-size:0.7rem;}
    `],
    animations:[
        ngIfAnimation
    ]
})

export class LoginComponent implements OnDestroy {

    userName
    userEmail
    password
    mouseoverLogin
    loginInvalid = false
    checkApi =false
    datas:any
    POSTStatus:any = {}
    pswReset:boolean = false;
    private ngUnsubscribe = new Subject()
    renewPass:boolean = true
    check:string = 'false'
    twoFaAuth:boolean = false
    tempToken:any = null
    timer:number = 60
    radiousLevel:number = 100
    timerInterval:any

    authCodeGroup = new FormGroup({
        authCode: new FormControl('',Validators.required)
    })
    
    //https://www.positronx.io/angular-7-httpclient-http-service/
    //json-server --watch server/db.json

    //apiUrl = 'https://api.github.com/users';

    constructor(
            private communicationService:CommunicationService, 
            private authService:AuthService,
            private router:Router, 
            private api:RestApiService, 
            private http:HttpClient,
            private tokenService:TokenService,
            private emailValidator:EmailValidatorService,
            private snackBar:MatSnackBar,
            private account:AccountService
            )
    { }

    ngOnInit(){
        this.communicationService.emitChange()
         var existingToken = localStorage.getItem("userToken")
         if((existingToken === null || existingToken === "null")){
             this.communicationService.emitChange()
         } else {
             this.router.navigateByUrl('home',{state:{token:existingToken}})
             this.tokenService.saveSubjectGlobally(existingToken)
             this.communicationService.emitChange()
         }

    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        clearInterval(this.timerInterval)
        this.clearVariables()
    }

    login(formValues){
        this.loginInvalid = false
        this.communicationService.emitChange()
        this.authService.loginUser(formValues.userName, formValues.password)
            .pipe(
               catchError((err: any) => {
                    //this.POSTStatus = {error:err.error}
                    this.POSTStatus = {error:err.status + ' ' + err.statusText}
                    this.communicationService.emitChange();
                    this.loginInvalid = true;
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                 let response = JSON.parse(resp)
                 if(response.isSuccess){
                     this.tokenService.saveSubjectGlobally(response.message)
                     if(response.isTwoFA){
                        this.check2fa(response.message)
                     } else {
                        this.router.navigateByUrl('home',{state:{token:response.message}})
                        this.communicationService.emitChange();
                     }
                 } else {
                     this.POSTStatus = {error:response.message}
                     this.loginInvalid = true;
                     this.communicationService.emitChange();
                 }
            });  
    }

    check2fa(tokenData){
        this.account.twoFAStatus()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp =>{
            let respObj = <any> resp.body
            if(respObj.isSuccess){
                this.twoFaAuth = true
                this.tokenService.logoutUser()
                this.tempToken = tokenData
                this.run2fainterval()
            } else {
                this.router.navigateByUrl('home',{state:{token:tokenData}})
            }
            this.communicationService.emitChange();
        })        
    }



    validate2Fa(){
        this.tokenService.saveSubjectGlobally(this.tempToken)
        this.account.validateTwoFaCode((this.authCodeGroup.value.authCode).toString())
        .pipe(
            catchError((err: any) => {
                this.tokenService.logoutUser()
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 1000,
                    panelClass: "bg-danger"
                });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.isSuccess? "Zalogowano" : respObj.message
                let respId = respObj.itemId
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
                if(respObj.isSuccess){
                    //this.closeAuthDialog(true)
                    //let token = this.tempToken
                    this.tempToken = null
                    this.tokenService.saveSubjectGlobally(respObj.message)
                    this.router.navigateByUrl('home',{state:{token:respObj.message}})
                } else {
                    this.tokenService.logoutUser()
                }
        });
    }    
    
    forgotPassword(){
        this.pswReset = true

    }

    cancelPswReset(){
        this.pswReset = false
    }

    valid(email){
        
        if(email === null || email === undefined){
            return true
        } else {
            return this.emailValidator.validateGivenEmail(email)
        }
    }

    resetService(email){
        this.communicationService.emitChange()
        this.authService.resetPsw(email)
            .pipe(
                catchError((err: any) => {
                    console.log(err)
                    this.communicationService.emitChange()
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                let text = ''
                resp.success === true ?  text="Wysłano prośbę o reset hasła": text="Wystąpił błąd podczas zakładania konta. Skontaktuj sie z administratorem"
                this.snackBar.open("Wysłano prośbę o reset hasła", "X", {
                    duration: 3000,
                    panelClass: resp.success === true ? "bg-success" :"bg-danger"
                  })
                  .afterDismissed()
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe(()=>{
                      this.pswReset = false;
                      this.router.navigate(['login']);
                  })
            });
    }

    run2fainterval(){
        this.timerInterval = setInterval(()=>{
            this.timer = this.timer - 1
            this.radiousLevel = (this.timer/60)*100
            if(this.timer === 0){
                clearInterval(this.timerInterval)
                this.clearVariables()
            }
        },1000)
    }

    clearVariables(){
        this.twoFaAuth = false
        this.timer = 60
        this.radiousLevel = 100
        this.tempToken = null
    }

}