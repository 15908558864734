import { Component, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { formatDate } from '../../../../node_modules/@angular/common';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { takeUntil, catchError } from '../../../../node_modules/rxjs/operators';
import { Subject, empty } from '../../../../node_modules/rxjs';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';


@Component({
    selector:'invoice-list',
    templateUrl:'./invoice-list.component.html'
})

export class InvoiceListComponent implements OnDestroy{
    //@ViewChild('invoiceDateInput',{static:false}) invoiceDateInput
    //inpVal:any
    @ViewChild('invoiceListObj',{static:true}) iL
    @ViewChild('newInvoiceFormObj',{static:true}) newInvoice
    dateStartMax
    dateEnd
    dateStartMax2
    dateEnd2
    teamsDDL:any = []
    showDelete:any
    private ngUnsubscribe = new Subject()
    
    constructor(
        private snackBar:MatSnackBar,
        private invoice:InvoiceService,
        private sharedservices: SharedServicesService
    ){}

    invoiceListArr=[]

    invoiceList = new FormGroup({
        employee: new FormControl('',Validators.required),
        periodFrom: new FormControl('',Validators.required),
        periodTo: new FormControl('',Validators.required)
    })

    newinvoiceForm = new FormGroup({
        companyName:new FormControl('',Validators.required),
        invoiceNr:new FormControl('',Validators.required),
        amount:new FormControl('',Validators.required),
        description:new FormControl('',Validators.required),
        saleDate:new FormControl('',Validators.required),
        paymentDate:new FormControl('',Validators.required),
        team:new FormControl('',Validators.required),
        notes:new FormControl('',Validators.required),
    })

    ngOnInit(){
        //this.newinvoiceForm.markAllAsTouched()
        this.sharedservices.getDDLTeams()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.teamsDDL = <any>resp.body
        })
    }

    markTouched(){
        this.newinvoiceForm.markAllAsTouched()
    }

    markUnTouched(){
        this.newinvoiceForm.markAsUntouched()
    }

    getInvoiceList(){
         let form = {
             employee: this.invoiceList.value.employee,
             dateFrom: formatDate(this.invoiceList.value.periodFrom,"yyyy-MM-dd","en_PL"),
             dateTo: formatDate(this.invoiceList.value.periodTo,"yyyy-MM-dd","en_PL")
         }

        this.invoice.getInvoices(form)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.invoiceListArr = <any> resp.body
            if(this.invoiceListArr.length > 0){
                this.invoiceListArr.sort(function(a,b){
                    let ac = a.invoiceId
                    let bc = b.invoiceId
                    return bc - ac
                })
            }
        })
        this.iL.resetForm()
    }

    addNewInvoice(){

        let form = {
            "CustomerName": this.newinvoiceForm.value.companyName,
            "InvoiceNr": this.newinvoiceForm.value.invoiceNr,
            "Description": this.newinvoiceForm.value.description,
            "SaleDate": formatDate(this.newinvoiceForm.value.saleDate,"yyyy-MM-dd","en_PL"),
            "PaidDate": formatDate(this.newinvoiceForm.value.paymentDate,"yyyy-MM-dd","en_PL") ,
            "TotalAmount": this.newinvoiceForm.value.amount,
            "Notes": this.newinvoiceForm.value.notes,
            "TeamId": this.newinvoiceForm.value.team       
        }
        this.invoice.addInvoice(form)
        .pipe(
            catchError((err: any) => {
                  console.log(err)
                  this.snackBar.open(err.error, "X", {
                      duration: 10000,
                      panelClass: "bg-danger"
                    });
                  return empty()
              }),
              takeUntil(this.ngUnsubscribe)
          )
          .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                if(respObj.isSuccess){
                    this.newInvoice.resetForm();
                }
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
          });
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    validateMinMaxDate(event){
        switch(event.targetElement.id){
            case "saleDate": {
                this.dateEnd = event.value
                break;
            }
            case "paymentDate": {
                this.dateStartMax = event.value               
                break;
            }
        }
    }

    validateMinMaxDate2(event){
        switch(event.targetElement.id){
            case "invoiceListFrom": {
                this.dateEnd2 = event.value
                break;
            }
            case "invoiceListTo": {
                this.dateStartMax2 = event.value               
                break;
            }
        }
    }

    deleteInvoice(invoiceId){
        this.invoice.deleteInvoice(invoiceId)
        .pipe(
           catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            this.invoiceListArr.splice(this.invoiceListArr.findIndex(itm=>itm.invoiceId === invoiceId),1)
            let mess = <any> resp
            this.snackBar.open(mess.message, "X", {
                duration: 10000,
                panelClass: mess.isSuccess? "bg-success" : "bg-danger"
              });
        })        
    }

    invoiceTabChange(event){
    }

    onAnimationDone(){

    }

}