import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, forkJoin } from '../../../../node_modules/rxjs';

@Injectable()

export class ManageBaseService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    getCampaignsTeamPriority(id){
        return this.http.get(`${this.baseUrl}` + '/Campaign/getCampaignsTeamPriority?teamId=' + id,{observe:'response',withCredentials:true})
    }

    getCampaignsRecordPriorityDDL(id){
        return this.http.get(`${this.baseUrl}` + '/Campaign/getCampaignsRecordPriorityDDL?campaignId=' + id,{observe:'response',withCredentials:true})
    }

    getCampaignsDetails(){
        return this.http.get(`${this.baseUrl}` + '/Campaign/getCampaignsDetails',{observe:'response',withCredentials:true})
    }

    doMultipleCalls(): Observable<any>{
        let resp1 = this.http.get(`${this.baseUrl}` + '/Campaign/getCampaignsDetails',{observe:'response',withCredentials:true})
        let resp2 = this.http.get(`${this.baseUrl}` + '/Campaign/getCampaignsTeams',{observe:'response',withCredentials:true})
        return forkJoin([resp1,resp2])
    }

    getCampaignsTeams(){
        return this.http.get(`${this.baseUrl}` + '/Campaign/getCampaignsTeams',{observe:'response',withCredentials:true})
    }

    updateCampaignsTeamsPriority(form){
        let arr ={
            "CampaignsList": form
        }
        return this.http.put(`${this.baseUrl}` + '/Campaign/updateCampaignsTeamsPriority', arr, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
        .pipe(
            map(data => {
                return data
            })
        );
    }

    updateCampaignsRecordsPriority(form){
        return this.http.put(`${this.baseUrl}` + '/Campaign/updateCampaignsRecordsPriority', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
        .pipe(
            map(data => {
                return data
            })
        );
    }

    updateCampaignsTeams(form){
        return this.http.put(`${this.baseUrl}` + '/Campaign/updateCampaignsTeams', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
        .pipe(
            map(data => {
                return data
            })
        );
    }

}