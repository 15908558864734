import {Component, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core'
import { Router } from '@angular/router'
import { DecodeTokenService } from '../services/authservice/decodetoken.service';
import { TokenService } from '../services/tokenservice/tokenservice.service';
import { Subscription, Subject } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { GetUserCallback } from '../services/getusercallback/getusercallback.service';
import { MatTabChangeEvent, MatTabGroup } from '../../../node_modules/@angular/material';
import { RecordService } from '../services/record/record.service';
import {takeUntil} from 'rxjs/operators'
import { CommunicationService } from '../services/communication/communication.service';


export interface tokenDetails {
    unique_name: string;
    BoxAccess: string;
    UserId: string;
    nbf: number;
    exp: number;
    iat:number;
  }

@Component({
    selector: 'home-page',
    templateUrl: './home-page.component.html',
    styleUrls:['./home-page.component.css'],
    encapsulation:ViewEncapsulation.None
})

export class HomePageComponent implements OnDestroy{
    userToken
    userAccess
    userAccessBoxAccess
    userCallback
    tokenDetails
    permissionDenied = true
    clientList:any
    tabIndex:string
    groupedObj:any
    ccl:any
    loadingCallbacks:boolean = false
    loadingManuals:boolean = false
    tabStageId:string
    tabProdStageId:string
    tabMyClients:string
    tabName:string
    refreshFlag:boolean = true
    selectedMenuTabIndex:number
    basicTab:boolean = true
    backupArr:any

    @ViewChild("menuTabs",{static:true}) menuTabRef:MatTabGroup

    private ngUnsubscribe = new Subject()
    
     constructor(
         private router:Router, 
         private decodeToken:DecodeTokenService, 
         private http:HttpClient,
         private getUserCallback:GetUserCallback,
         private tokenService:TokenService,
         private record:RecordService,
         private communicationService:CommunicationService
    ){
         this.userToken = this.tokenService.getSubjectToken()
         this.userAccessBoxAccess = this.userToken === 'null' ?  router.navigate(['/login']):this.decodeToken.decodeToken(this.userToken)
         this.userAccess= this.userAccessBoxAccess === null ? null:JSON.parse(this.userAccessBoxAccess.BoxAccess)
     }

    ngOnInit(){      
        this.loadingCallbacks = true
        sessionStorage.removeItem("manual")
        this.communicationService.refreshManual$.subscribe(data=>{
            if(this.refreshFlag === true){
                this.record.getStageRecords(data,"Refresh")
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp=>{
                    this.clientList = <any> resp.body
                    this.ccl = this.clientList
                    this.loadingManuals = true
                })
            }
        })
    }

    openTab(val:string, ev){
        document.querySelector("[class*='l-nav-show']").classList.remove("l-nav-show")
        ev.target.parentElement.classList.toggle("l-nav-show")
        console.log(ev)
        document.getElementById(val).click()
    }

    ngAfterViewInit(){
        setTimeout(()=>{
            if(localStorage.getItem("menuTabIndex") === "0"){
                this.getUserCallback.getCallbackList()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp =>{
                    resp.status === 204 ? this.userCallback = [] :this.userCallback = resp.body
                    this.userCallback.length === 0 ? null : this.userCallback.sort(function(a,b){
                        let ac = new Date(a.preferedDate).getTime()
                        let bc = new Date(b.preferedDate).getTime()
                        return ac - bc
                    }) && this.groupByCallbackList(this.userCallback);
                    this.loadingCallbacks = false
                })
                
            }
        },1000)

        setTimeout(()=>{
            if(localStorage.getItem("menuTabIndex") === null){
                localStorage.setItem("menuTabIndex","0")
                this.selectedMenuTabIndex = 0
            } else {
                this.selectedMenuTabIndex = parseInt(localStorage.getItem("menuTabIndex"))
            }
        },500)
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
      }

    checkVisibility(value){
        return this.decodeToken.accessGranted(value)
    }

    checkHomePermissions(){
        var pageList =[]
        for(var key in this.userAccess){
            pageList.push(this.userAccess[key].WebBoxName)
        }
        return pageList.filter(res => res.includes("home/")).length === 0
    }

    onAnimationDone(){
        if(this.tabStageId !== undefined){
            this.record.getStageRecords(this.tabStageId,this.tabName)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp=>{
                this.clientList = <any> resp.body
                if(this.clientList !== null){
                    this.clientList.sort(function(a,b){
                        let ac = a.lastName
                        let bc = b.lastName
                        return ac - bc
                    })
                }
                this.ccl = this.clientList
                this.loadingManuals = true
                this.communicationService.emitChange()
            })
            this.tabStageId = undefined
            this.tabName = undefined
        } else if(this.tabProdStageId !== undefined){
            let type = this.tabProdStageId
            this.record.getProductsList(this.tabProdStageId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp=>{
                this.clientList = <any> resp.body
                if(this.clientList !== null){
                    this.clientList.sort(function(a,b){
                        let ac = a.creditApplicationId
                        let bc = b.creditApplicationId
                        return bc - ac
                    })
                }
                this.ccl = this.clientList
                if(type === '10,11,12,13'){
                    console.log(this.ccl)
                    if(this.ccl){
                        this.clientList = this.ccl.filter(itm=>itm.result === 'Uruchomienie')
                    }
                }
                
                this.loadingManuals = true
                this.communicationService.emitChange()
            })
            this.tabProdStageId = undefined
        } else if(this.tabMyClients !== undefined){
            
            this.tabMyClients = undefined
        }


    }

    onTabClick(event: MatTabChangeEvent){
        this.loadingManuals = false
        this.clientList = []
        localStorage.setItem("menuTabIndex",event.index.toString())
        this.tabIndex = event.tab.textLabel
        switch(event.tab.textLabel){
            case "Callbacki":{
                this.loadingCallbacks = true
                setTimeout(()=>{
                    this.getUserCallback.getCallbackList()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(resp =>{
                        resp.status === 204 ? this.userCallback = [] :this.userCallback = resp.body
                        this.userCallback.length === 0 ? null : this.userCallback.sort(function(a,b){
                            let ac = new Date(a.preferedDate).getTime()
                            let bc = new Date(b.preferedDate).getTime()
                            return ac - bc
                        }) && this.groupByCallbackList(this.userCallback);
                        this.loadingCallbacks = false
                    })
                },1000)
                break;
            }
            case "Listy":{
                this.communicationService.emitChange()
                this.tabStageId = '4'
                this.tabName = "Styczeń"
                break;
            }
            case "Prospekt":{
                this.communicationService.emitChange()
                this.tabStageId = '5'
                this.tabName = "Prospekt"
                 break;
            }
            case "Komplet Dokumentów":{
                this.communicationService.emitChange()
                this.tabStageId = '6'
                this.tabName = "Komplet"
                 break;
            }
            case "Produkty":{
                this.communicationService.emitChange()
                this.tabProdStageId = '7'
                break;                
            }
            case "Wnioski":{
                this.communicationService.emitChange()
                this.tabProdStageId = '8'
                break;                
            }
            case "W Banku":{
                this.communicationService.emitChange()
                this.tabProdStageId = '9'
                break;                
            }
            case "Zakończone":{
                this.communicationService.emitChange()
                this.tabProdStageId = '10,11,12,13'
                break;                
            }
            case "Moje wnioski":{
                this.communicationService.emitChange()
                this.tabProdStageId = ''
                break;
            }
        }
    }

    innerTabChange(event){
        switch(event.tab.textLabel){
            case "0":{
                //this.basicTab = true
                break;
            }
            case "1":{
                //this.basicTab = false
                break;
            }
            case "2":{
                //this.basicTab = false
                let filtered = this.ccl.filter(itm=>itm.result === 'Uruchomienie')
                this.clientList = filtered
                break;
            }
            case "3":{
                //this.basicTab = false
                let filtered = this.ccl.filter(itm=>itm.result === 'Rezygnacja')
                this.clientList = filtered
                break;
            }
        }
    }
    
    groupByCallbackList(callbacks){
        this.groupedObj = []
        let name
        let stageId 
        callbacks.forEach(data =>{
            name = this.getNameByStage(data.callbackStageID)
            stageId = data.callbackStageID
            this.groupedObj.map(function(e){return e.key;}).indexOf(data.callbackStageID) === -1? this.groupedObj.push({
                key : stageId,
                value:name
            })
            :null
        })
    }

    getNameByStage(stageId){
        let stageName
        switch(stageId){
            case 3:{
                stageName = "Callback"
                break;
            }
            case 4:{
                stageName = "Styczeń"
                break;
            }
            case 5:{
                stageName = "Prospekt"
                break;
            }
            case 6:{
                stageName = "Dokumenty"
                break;
            }
            case 7:{
                stageName = "Produkty"
                break;
            }
            case 8:{
                stageName = "Wnioski"
                break;
            }
            case 9:{
                stageName = "Bank"
                break;
            }
            default:{
                stageName = "Nieznany"
            }
        }
        return stageName;
    }
}