import {Component, Input, Output,EventEmitter, ViewChild, OnDestroy} from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from '../services/authservice/auth.service'
import {Observable, empty, Subject} from 'rxjs';
import { RestApiService } from '../shared/rest-api.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { tap, catchError, map, delay } from 'rxjs/operators';
import { CommunicationService } from '../services/communication/communication.service';
import { TokenService } from '../services/tokenservice/tokenservice.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidateGuidService } from '../services/validateGUID/validate-guid.service';
import { SharedServicesService } from '../services/sharedservices/sharedservices.service';
import {takeUntil} from 'rxjs/operators'

export interface GUIDresp {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    expirationDate: string;    
}

@Component({
    selector: 'password-manager',
    templateUrl:'./password-manager.component.html',
    styles:[`
    .passmanager {float:right; color:#E05C65;padding-left:10px;font-size:0.8rem;}
    .password-tip {color:white;font-size:0.7rem;}
    .custom-font-color {color: var(--primary)}
    .custom-h-font-shadow {text-shadow:none!important;}
    `]
})

export class PasswordManagerComponent implements OnDestroy{
    @ViewChild('submitForm',{static:true}) submitForm:any
    submitPass
    resubmitPass
    mouseoverSubmit
    submitInvalid = false
    oprType
    oprGUID
    //pwdPattern = "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,16}$";  
    formValid
    GUIDresp:GUIDresp ={
        userId:0,
        email: "",
        firstName: "",
        lastName: "",
        expirationDate: ""     
    }
    POSTStatus = {}
    show = true
    newPass:any
    renewPass:any
    passEqual = false
    private ngUnsubscribe = new Subject()


constructor(
    private fb:FormBuilder,
    private route:ActivatedRoute, 
    private router:Router, 
    private validateGUID:ValidateGuidService,
    private communicationService:CommunicationService,
    private sharedService:SharedServicesService
){}

    ngOnInit(){
        this.oprType = this.route.snapshot.queryParamMap.get('type')
        this.oprGUID = this.route.snapshot.queryParamMap.get('guid')
        if((this.oprType === null) || (this.oprGUID === null) ){
            this.router.navigate(['/error'])
        } else {
            this.validateGUID.validateGUID(this.oprGUID)
            .pipe(
                catchError((err:any)=>{
                console.log(err)
                this.router.navigate(['/error'])
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
            )
            .subscribe( resp => {
                if(this.sharedService.dateLessThanCurrent(resp.expirationDate)){
                    this.router.navigate(['/error'])
                } else {
                    this.show = true
                    this.GUIDresp = resp
                }
               
            })
        }
    }
    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    passIsValid(providedPass){
        this.formValid = this.fb.group({
            string:[providedPass,[Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#^]).{7,16}')]]
        })
        return this.formValid.status === 'INVALID' ? false:true
    }

    submit(formValues){
        this.communicationService.emitChange();
        var userId = this.GUIDresp.userId.toString()
        var psw = formValues.renewPass

        this.validateGUID.passService(userId,psw)
        .pipe(
            catchError((err: any) => {
                 this.POSTStatus = {error:err.error}
                 this.communicationService.emitChange();
                 return empty()
             }),
             takeUntil(this.ngUnsubscribe)
         )
         .subscribe(resp =>{
             this.communicationService.emitChange();
             this.router.navigate(['login']);
         });
    }

}