import {Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges} from '@angular/core'
import { PermissionsService } from '../../../services/permissions/permissions.service';
import { MatTableDataSource, MatSnackBar } from '../../../../../node_modules/@angular/material';
import { tap, catchError, map, takeUntil } from 'rxjs/operators';
import {empty, Subject} from 'rxjs';
import { DecodeTokenService } from '../../../services/authservice/decodetoken.service';


@Component({
    selector: 'permissions-list-details',
    templateUrl: './permissions-list-details.component.html',
    styles:[`
    table {
        width: 100%;
      }
    `],
})

//export class PermissionsListDetailsComponent implements OnChanges{
  export class PermissionsListDetailsComponent {
    @Input() data:any
    @Input() editFlag:boolean
    
    displayedColumns: string[] = ['description','exist'];
    dataSource:any
    mouseoverChange:number
    showChange:number
    private ngUnsubscribe = new Subject()
    

    constructor(
      private permission:PermissionsService,
      private snackBar:MatSnackBar,
      private decodeToken: DecodeTokenService
    ){
      
    }

    updatePermission(event,accessId,accessName){
      let accessInfo = {
        AccessLevelId: this.data,
        WebBoxId: accessId,
        Exist: event.checked
      }

      if(!event.source._disabled){
        let information = !event.checked ? "Usunięto uprawnienie: " : "Nadano uprawnienie: "

        this.permission.updateAccessLevel(accessInfo)
            .pipe(
              catchError((err: any) => {
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.snackBar.open(information + accessName, "X", {
                    duration: 4000,
                    panelClass: !event.checked ? "bg-warning":"bg-success"
                  });
                this.showChange = -1
            });
      }
    }

    applyFilter(value:string) {
      this.dataSource.filter = value.trim().toLowerCase();
    }

    convertDS(){
      if(!(this.dataSource instanceof MatTableDataSource)){
        this.dataSource = new MatTableDataSource(this.dataSource)
      }
    }

    ngOnDestroy(){
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }
}