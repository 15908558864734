import {Component, OnDestroy} from '@angular/core'
import { PermissionsService } from '../../services/permissions/permissions.service';
import { PermissionsListDetailsComponent } from './permissionslistdetails/permissions-list-details.component';
import { DecodeTokenService } from '../../services/authservice/decodetoken.service';
import { Subject } from '../../../../node_modules/rxjs';
import {takeUntil} from 'rxjs/operators'

@Component({
    selector: 'permissions-list',
    templateUrl: './permissions-list.component.html'
})

export class PermissionsListComponent implements OnDestroy{
    accessBoxes:any
    accessLevel:number
    canEdit:boolean
    private ngUnsubscribe = new Subject()

    constructor(
        private permissions:PermissionsService,
        private decodeToken:DecodeTokenService
    ){
        this.permissions.getAccessLevels()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp =>{
            this.accessBoxes = <any> resp.body
        })
        this.canEdit = this.decodeToken.accessGranted("editUser")
    }
    isOpenedAccess(level:any,ref:PermissionsListDetailsComponent){
        this.permissions.getWebBoxes(level)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp =>{
            ref.dataSource = <any> resp.body
            this.accessLevel = level
        })
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    
}