import { Component, OnDestroy } from "@angular/core";
import { HolidayService } from '../../services/holiday/holiday.service';
import { takeUntil, catchError } from '../../../../node_modules/rxjs/operators';
import { Subject, empty } from '../../../../node_modules/rxjs';
import { CommunicationService } from '../../services/communication/communication.service';
import { MatSnackBar } from '../../../../node_modules/@angular/material';


@Component({
    selector:'leave-requests',
    templateUrl:'leave-requests.component.html'
})

export class LeaveRequestsComponent implements OnDestroy{
    selectedLabelIndex:string = "0"
    loadingTemplate:boolean = true
    teamFilterArr:any = []
    temp_teamFilterArr:any = []
    private ngUnsubscribe = new Subject()

    constructor(
        private holiday: HolidayService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar
    ){}

    requestsList =[
    ]
    filter_requestsList=[]
    requestsApprovedList =[
    ]
    filter_requestsApprovedList=[]

    ngOnInit(){
        this.getAllLeaveRequests(1)
    }

    manageReqest(id,type){

        let form={
            "LeaveRequestId": id,
            "NewStatus": type
        }

        this.communicationService.emitChange()
        this.holiday.changeLeaveRequestStatus(form)
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                  let respObj = JSON.parse(<any> resp.body)
                  let message = respObj.message
                  let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                  this.snackBar.open(message, "X", {
                      duration: 3000,
                      panelClass: messageClass
                  });
                  switch(type){
                      case 2:{
                        this.requestsApprovedList = this.requestsApprovedList.filter(itm=>itm.leaveRequestId !== id)
                        break;
                      }
                      default:{
                        this.requestsList = this.requestsList.filter(itm=>itm.leaveRequestId !== id)
                        break;
                      }
                  }
            }); 
        
        
    }

    leaveRequestTabChange(event){
        switch(event.tab.textLabel){
            case "0":{
                this.selectedLabelIndex = event.tab.textLabel
                this.getAllLeaveRequests(1)
                break;
            }
            case "1":{
                this.loadingTemplate = true
                this.selectedLabelIndex = event.tab.textLabel
                break;
            }
            case "2":{
                this.selectedLabelIndex = event.tab.textLabel
                this.getAllLeaveRequests(3)
                break;
            }
        }
    }

    onAnimationDone(){
        setTimeout(()=>{
            this.loadingTemplate = false
        },2000)
    }

    getAllLeaveRequests(type){
        this.teamFilterArr = []
        this.holiday.getAllLeaveRequest(type)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            switch(type){
                case 1:{
                    this.requestsList = <any> resp.body
                    this.requestsList.sort(function(a,b){
                        let ac = a.leaveRequestId
                        let bc = b.leaveRequestId
                        return bc - ac
                    })
                    this.filter_requestsList = this.requestsList
                    let temp_teamFilterArr = new Set()
                    for(var i of this.requestsList){
                        temp_teamFilterArr.add(i.teamName)
                    }
                    this.teamFilterArr = Array.from(temp_teamFilterArr)
                    break;
                }
                case 3:{
                    this.requestsApprovedList = <any> resp.body
                    this.requestsApprovedList.sort(function(a,b){
                        let ac = a.leaveRequestId
                        let bc = b.leaveRequestId
                        return bc - ac
                    })
                    this.filter_requestsApprovedList = this.requestsApprovedList
                    this.requestsList = <any> resp.body
                    let temp_teamFilterArr = new Set()
                    for(var i of this.requestsApprovedList){
                        temp_teamFilterArr.add(i.teamName)
                    }
                    this.teamFilterArr = Array.from(temp_teamFilterArr)
                    break;
                }
            }
        })
    }

    applyTeamFilter(event,type){
        switch(type){
            case "request":{
                this.requestsList = this.filter_requestsList.filter(el=>
                el.teamName.toLowerCase().includes(event.value.toLowerCase())
                )
                break;
            }
            case "approved":{
                this.requestsApprovedList = this.filter_requestsApprovedList.filter(el=>
                el.teamName.toLowerCase().includes(event.value.toLowerCase())
                )
                break;
            }
        }
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}