import {Component,EventEmitter, ViewEncapsulation,ElementRef, ViewChild, Output, Input, SimpleChanges, OnDestroy} from '@angular/core'
import { Router } from '@angular/router';
import { MatRadioButton, MatRadioChange, MatSnackBar } from '@angular/material';
import { RecordService } from '../../services/record/record.service';
import { CommunicationService } from '../../services/communication/communication.service';
import { tap, catchError, map, takeUntil } from 'rxjs/operators';
import {empty, Subject} from 'rxjs';
import { TokenService } from '../../services/tokenservice/tokenservice.service';
import { DecodeTokenService } from '../../services/authservice/decodetoken.service';
import { formatDate } from '../../../../node_modules/@angular/common';


export interface tokenDetails {
    unique_name: string;
    BoxAccess: string;
    UserId: string;
    nbf: number;
    exp: number;
    iat:number;
  }

@Component({
    selector:'client-contact-result',
    templateUrl:'./client-contact-result.component.html',
    styles:[`
        /*.mat-radio-button {margin-left:2rem;}
        .mat-radio-outer-circle {border-color:white;}
        .mat-form-field-appearance-legacy {color:white!important}
        .mat-form-field-label {color:white!important}
        .mat-form-field-underline {background-color:transparent!important}*/
        .custom-margin {margin-left: 0.4rem; margin-right: 0.4rem;}
        /*.mat-form-field-infix {width:92px !important}
        .mat-select-value {color:white!important}
        .mat-input-element {color: #ffd740!important; caret-color:white!important;}
        .mat-select-value-text {color: #ffd740!important;}
        .mat-form-field-ripple {background-color:transparent!important}
        .mat-select-arrow {color:white!important;}
        .mat-radio-button {margin-left: 0px;}
        .mat-icon {vertical-align: middle;}*/
        .icon-container {text-align:center}
        .icon-custom-class {width: 60px!important;height:60px!important;font-size:50px!important; text-shadow: 1px 1px 1px black;}
        .radio-group {
            display: flex!important;
            flex-direction: column!important;
            margin: 0px 0!important;
          }
    `],
    encapsulation:ViewEncapsulation.None
})

export class ClientContactResultComponent implements OnDestroy {
    formInvalid: boolean = true
    subradio: any = null
    selectedRadio: any = null
    notes:any = null
    allDicts = []
    reqSubCode:boolean = true
    showCalendar:boolean = false
    showNotesComponent:boolean
    userToken:any
    tokenDetails:any
    calendarValue:any = null
    calendarFlag:any = false
    isManual:boolean = false
    @Output() change= new EventEmitter<any>()
    @Input() dict:any
    @Input() recordId:number
    @Input() OriginCallbackId:number
    @Input() creditAppliactionId:number
    //@Input() drawerFlag:boolean
    private ngUnsubscribe = new Subject()

    constructor (
        private router:Router,
        private record:RecordService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar,
        private tokenService:TokenService,
        private decodeToken:DecodeTokenService
    ){
        
    }

    ngOnInit(){
        // this.isManual = sessionStorage.getItem("manual")?true:false
        this.communicationService.changeEmittedDrawerForm$.subscribe(data=>{
            this.selectedRadio = null
            this.subradio = null
            this.formInvalid = true    
        })
    }

    ngOnChanges(changes:SimpleChanges){
        this.allDicts = changes.dict.currentValue
    }

    assignCalendarValue(ev){
        this.calendarValue = ev
    }

    assignCalendarFlag(ev){
        this.calendarFlag = ev
    }

    saveAndBackToPanel(){
        this.userToken = this.tokenService.getSubjectToken()
        this.tokenDetails = this.decodeToken.decodeToken(this.userToken) as tokenDetails
        let user = parseInt(this.tokenDetails.UserId)

        let recordDetails = {
            "RecordID": this.recordId,
            "ContactResultID": this.subradio !== null? parseInt(this.subradio):parseInt(this.selectedRadio),
            "ContactNotes": this.notes,
            "CreditApplicationID": this.creditAppliactionId,
            "CallbackPreferredDate": this.calendarValue === null ? null:this.returnCorrectDate(this.calendarValue),
            //"OriginCallbackId": this.OriginCallbackId        
        }
        this.communicationService.emitChange()
        this.record.saveRecord(recordDetails)
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                this.record.removeClientFlagFromStorage() ? this.router.navigate(['home']) : null
                sessionStorage.removeItem("manual")
                //this.communicationService.emitExpPanel(true)
                this.communicationService.emitExpPanel(true)
                this.snackBar.open("Zapisano nowy rezultat kontaktu z klientem", "X", {
                    duration: 4000,
                    panelClass: "bg-success"
                  })
            });        
    }

    onChange(mrChange: MatRadioChange) {
            let dict = mrChange.source._elementRef.nativeElement.attributes
            this.formInvalid = false
            this.subradio = null
            this.calendarValue = null
            this.calendarFlag = false
            mrChange.value === "21" ? this.change.emit({type:"drawer",value:mrChange.value}): (mrChange.value === "29")  ? this.change.emit({type:"finalResultDrawer",value:mrChange.value}):this.change.emit({type:"none",value:null})
    }

    onSubChange(mrChange: MatRadioChange) {
        this.formInvalid = false;
         this.reqSubCode = true;
         this.calendarValue = null;
        (mrChange.value === "29")  ? this.change.emit({type:"finalResultDrawer",value:mrChange.value}):this.change.emit({type:"none",value:null})
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    flagSub(){
        this.reqSubCode = false
    }

    boxSizeCheck(allDicts){
        let size = "col-md-" + (12/allDicts)
        return size
    }

    calendarSwitch(){
        this.showCalendar = false
    }

    autoresize(){
        //recordNotes
        let  textArea = document.getElementById("recordNotes")       
        textArea.style.overflow = 'hidden';
        textArea.style.height = '48px';
        textArea.style.height = textArea.scrollHeight + 'px';        
    }

    returnCorrectDate(time){
        let date1 = new Date(time).getTime()
        let date2 = new Date(Date.now()).getTime()
        let result = date1 - date2
        
        if(result < 0){
            return formatDate(new Date().setMinutes(new Date().getMinutes() + 1),"yyyy-MM-dd HH:mm","en_PL")
        } else {
            return formatDate(time,"yyyy-MM-dd HH:mm","en_PL")
        }
    }

}