import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class SharedServicesService {
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    dateLessThanCurrent(date){
        let date1 = new Date(date).getTime()
        let date2 = new Date(Date.now()).getTime()
        let result = date1 - date2
        let diff = (result/1000)/60
        return diff < 0 ? true:false         
    }

    getAllDDL(){
        return this.http.get(`${this.baseUrl}` + '/Users/getDDLUser' ,{observe:'response',withCredentials:true}); 
    }

    getCreditAppDDL(){
        return this.http.get(`${this.baseUrl}` + '/Product/getCreditAppDDL' ,{observe:'response',withCredentials:true}); 
    }

    getDDLTeams(){
        return this.http.get(`${this.baseUrl}` + '/Users/getDDLTeams' ,{observe:'response',withCredentials:true}); 
    }

    getDDLTeamUsers(userId){
        return this.http.get(`${this.baseUrl}` + '/Users/getDDLTeamUsers?teamId=' + userId ,{observe:'response',withCredentials:true}); 
    }

    getDDLCampaignList(){
        return this.http.get(`${this.baseUrl}` + '/Record/getCampaignsList',{observe:'response',withCredentials:true}); 
    }

    getLeaveRequestTypes(){
        return this.http.get(`${this.baseUrl}` + '/Holiday/getLeaveRequestTypes',{observe:'response',withCredentials:true}); 
    }
}