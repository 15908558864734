import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from '@angular/forms';

@Injectable()

export class EmailValidatorService {
    formValid:any = false

    constructor(private fb:FormBuilder){}
    
    validateGivenEmail(providedEmail){
        this.formValid = this.fb.group({
            string:[providedEmail,[Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]]
        })
        return this.formValid.status === 'INVALID' ? true:false       
    }

}