import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
  action?: string;
}

/** Constants used to fill up our data base. */
const Data: string[] = [
  '02-01-2020', '15-01-2020', '01-02-2020', '14-02-2020', '01-03-2020', '22-03-2020', '01-04-2020'
];
const BaseName: string[] = [
  'Baza Styczen Nowi', 'Baza Styczen Algorytm', 'Baza Luty Nowi', 'Baza Luty Algorytm', 'Hot deal', 'Baza Emaryt', 'Baza Pierwszy Kredyt'
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'base-history',
  styleUrls: ['base-history.component.css'],
  templateUrl: 'base-history.component.html',
  encapsulation:ViewEncapsulation.None
})
export class BaseHistoryComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'progress', 'color', 'action'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() {
    // Create 100 users
    const users = Array.from({length: 5}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(users);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name = BaseName[Math.round(Math.random() * (BaseName.length - 1))] + ' ' +
      BaseName[Math.round(Math.random() * (BaseName.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    name: name,
    progress: Math.round(Math.random() * 100).toString(),
    color: Data[Math.round(Math.random() * (Data.length - 1))],
    action: "<a>Action</a>"
  };
}
