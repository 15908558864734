import {Component, ViewEncapsulation, OnDestroy, Input, SimpleChanges, ViewChild, Inject} from '@angular/core'
import { faFilePdf,faFileImage,faDownload,faEdit, faTrashAlt, faFileExcel, faFile, faFileWord, faTimes} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import {NavigationExtras} from '@angular/router'
import { RecordService } from '../services/record/record.service';
import { TokenService } from '../services/tokenservice/tokenservice.service';
import { DecodeTokenService } from '../services/authservice/decodetoken.service';
import { CommunicationService } from '../services/communication/communication.service';
import { MatTabChangeEvent, MatSnackBar, MatDrawer, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject,empty } from '../../../node_modules/rxjs';
import { FileService } from '../shared/file.service';
import { tap, catchError, map, takeUntil } from 'rxjs/operators';
import { toBase64String } from '../../../node_modules/@angular/compiler/src/output/source_map';
import { FormGroup, FormControl, Validators, FormArray } from '../../../node_modules/@angular/forms';
import { DialogData } from '../controlpanel/employeelist/employee-list.component';
import { SharedServicesService } from '../services/sharedservices/sharedservices.service';
import { KeyValue, formatDate } from '../../../node_modules/@angular/common';
import { THIS_EXPR } from '../../../node_modules/@angular/compiler/src/output/output_ast';


export interface tokenDetails {
    unique_name: string;
    BoxAccess: string;
    UserId: string;
    nbf: number;
    exp: number;
    iat:number;
  }

@Component({
    selector: 'client-contact-details',
    templateUrl: './client-contact-details.component.html',
    styles:[`
        .mat-tab-label-content {color:white}
        .mat-tab-body-content {height:100%}
        .mat-ink-bar {background-color:white!important}
        .mat-tab-header-pagination-chevron {border-color:white}
        .mat-tab-header-pagination-disabled {border-color:red}
        .mat-tab-body-content {overflow:hidden!important}
        .mat-tab-label{min-width:80px!important}
        .mat-tab-label-active {
            background-color:#253340!important;
        }
        .custom-font-light {color: #a7a7a7;}
        .custom-font-dark {color:#a7a7a7}
        .custom-mt-6 {margin-top:1rem}
        .custom-mt-5 {margin-top:0.7rem}
        .custom-ml-6 {margin-left:1rem}
        .custom-inline {display:inline}
        .custom-inline-right {display:inline; float:right}
        .fa-margin {margin-left: 0.4rem; margin-right:0.4rem}
        .fa-margin-right {margin-right:0.5rem;}
        .custom-font-1 {
            font-size:1rem;
        }
        em {float:right; color:red;padding-left:10px;}
    `],
    encapsulation:ViewEncapsulation.None
})

export class ClientContactDetailsComponent implements OnDestroy {
    @Input() recId:number
    @Input() clientm:any
    @Input() resultsBoxesm:any
    @Input() recordIdm:number
    @Input() OriginCallbackIdm:number
    @Input() hideClientData:boolean
    @ViewChild("formDrawer",{static:true}) drawer:MatDrawer
    @ViewChild("finalResultDrawer",{static:true}) finalFormDrawer:MatDrawer

    tabs: any
    faFilePdf: any = faFilePdf
    faFileImage: any = faFileImage
    faFileExcel: any = faFileExcel
    faDownload: any = faDownload
    faEdit: any = faEdit
    faTrashAlt: any =faTrashAlt
    faFile:any = faFile
    faWord:any = faFileWord
    faTimes:any = faTimes
    contactId: any = ""
    detailsType: any = 1
    client:any
    userToken:any
    tokenDetails:any
    recordInterval:any
    disableAnimation = true;
    resultsBoxes:any
    recordHistory:any
    recordDocuments:any
    recordId:number
    OriginCallbackId:number
    isManual:boolean = false
    hasAdditionalData:boolean = false
    loadingBar:boolean = false
    drawerFlag:boolean = false
    contactResultId: number
    creditApplicationArr:any
    creditAppliactionId_val:number 
    editCredForm:boolean = false
    canSubmit:boolean
    creditApplicationHistory:any
    labelPosition='before'
    historyType = "wniosku"
    dateStart = new Date()
    bankDDL:any
    productDDL:any
    productTypeDDL:any
    fileErrors:any = []
    private ngUnsubscribe = new Subject()

    creditApplication = new FormGroup({
        productType: new FormControl('',Validators.required),
        product: new FormControl('',Validators.required),
        bank: new FormControl('',Validators.required),
        offerName: new FormControl(),
        offerAmount: new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$")]),
        offerPeriod: new FormControl(),
        notes: new FormControl(),
        files: new FormArray([],Validators.required)
    })

    finalResultForm = new FormGroup({
        fullName: new FormControl(),
        //owner: new FormControl('',Validators.required),
        bankName: new FormControl(),
        broker: new FormControl('',Validators.required),
        netAmount: new FormControl('',[Validators.required]),
        grossAmount: new FormControl('',[Validators.required]),
        creditConditions : new FormControl('',Validators.required),
        fv:new FormControl('',Validators.required),
        overCommision: new FormControl(''),
        releasedDate: new FormControl(''),
        invoicePaid: new FormControl(''),
        invoiceValue: new FormControl(''),
        notes: new FormControl('')
    })
    
    constructor(
        private route:ActivatedRoute, 
        public router: Router,
        private record:RecordService,
        private tokenService:TokenService,
        private decodeToken:DecodeTokenService,
        private communicationService:CommunicationService,
        private file:FileService,
        private snackBar:MatSnackBar,
        private dialog:MatDialog,
        private sharedServices: SharedServicesService
    ){
        //this.contactId = this.router.getCurrentNavigation().extras.state.id
        if(this.router.getCurrentNavigation() === null){
            
        } else if(this.router.getCurrentNavigation().extras.state !== undefined){
            this.client = this.router.getCurrentNavigation().extras.state.body
            console.log("client", this.client.contacResultJsonSring)
            console.log("client", JSON.parse(this.client.contacResultJsonSring))
            if(this.client.contacResultJsonSring !== null){
                this.resultsBoxes = JSON.parse(this.client.contacResultJsonSring).childItems
            } else {
                this.resultsBoxes = this.client.contacResultJsonSring
            }
            
            this.recordId = this.client.recordId
            this.record.storeClientData(this.client.creditApplicationId)
            this.OriginCallbackId = this.client.callbackId
            this.creditAppliactionId_val = this.client.creditApplicationId
            this.client.creditApplicationId > 0 ? this.getCreditApplicationDetails(this.client.recordId,this.client.creditApplicationId):null
        } else if(localStorage.getItem("body")){
            if(sessionStorage.getItem("manual")){
                this.record.getRecordById(sessionStorage.getItem("manual"),localStorage.getItem("body"))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp=>{
                    this.client = <any> resp.body
                    if(this.client.contacResultJsonSring !== null){
                        this.resultsBoxes = JSON.parse(this.client.contacResultJsonSring).childItems
                    } else {
                        this.resultsBoxes = this.client.contacResultJsonSring
                    }
                    this.recordId = this.client.recordId
                    this.OriginCallbackId = this.client.callbackId 
                    this.isManual = true
                    this.creditAppliactionId_val = parseInt(localStorage.getItem("body"))
                    parseInt(localStorage.getItem("body")) > 0 ? this.getCreditApplicationDetails(this.client.recordId,parseInt(localStorage.getItem("body"))):null                  
                })
            } else {
                //this.userToken = this.tokenService.getSubjectToken()
                //this.tokenDetails = this.decodeToken.decodeToken(this.userToken) as tokenDetails
                //let user = parseInt(this.tokenDetails.UserId)
                this.record.getRecord()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp => {
                    if(resp){
                        this.client = <any> resp.body
                        if(this.client.contacResultJsonSring !== null){
                            this.resultsBoxes = JSON.parse(this.client.contacResultJsonSring).childItems
                        } else {
                            this.resultsBoxes = this.client.contacResultJsonSring
                        }
                        this.recordId = this.client.recordId
                        this.OriginCallbackId = this.client.callbackId
                        this.creditAppliactionId_val = this.client.creditApplicationId
                        this.client.creditApplicationId > 0 ? this.getCreditApplicationDetails(this.client.recordId,this.client.creditApplicationId):null
                    }
                })
            }
        } else {
            this.router.navigate(['home'])
        }
    }

    ngAfterViewInit(): void {
    
        setTimeout(() => this.disableAnimation = false);
        setTimeout(()=>{
            if(this.router.url === "/search"){
                this.record.getRecordHistory(this.client.recordId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp => {
                    this.recordHistory = <any> resp.body
                    this.recordHistory.sort(function(a,b){
                        let ac = new Date(a.contactDateTime).getTime()
                        let bc = new Date(b.contactDateTime).getTime()
                        return bc - ac
                    })
                    this.creditApplicationHistory = this.recordHistory
                    if(this.creditAppliactionId_val > 0){
                        this.recordHistory = this.creditApplicationHistory.filter(itm=>itm.creditApplicationId === this.creditAppliactionId_val)
                    }
                })
            }
        },1000)
    }

    ngOnInit(){
        //this.communicationService.emitChange()
        this.isManual = sessionStorage.getItem("manual")?true:false
        this.applicationFormValidators()
        if(this.client !== undefined){
            let additionalDataLength = (
                this.client.data1 === null ? 0:1 +
                this.client.data2 === null ? 0:1 +
                this.client.data3 === null ? 0:1 +
                this.client.data4 === null ? 0:1 +
                this.client.data5 === null ? 0:1 +
                this.client.data6 === null ? 0:1 +
                this.client.data7 === null ? 0:1 +
                this.client.data8 === null ? 0:1 +
                this.client.data9 === null ? 0:1 +
                this.client.data10 === null ? 0:1
            )
            this.hasAdditionalData = additionalDataLength > 0 ? true:false
        } else {
            this.isManual = false
        }


        this.recordInterval = setInterval(()=>{
            if(this.client){
                clearInterval(this.recordInterval)
                //this.communicationService.emitChange()
            }
        },1000)  
    }

    ngOnChanges(changes:SimpleChanges){
        if(changes.clientm.currentValue !== undefined){
                 this.client = <any> changes.clientm.currentValue
                 this.resultsBoxes = changes.resultsBoxesm.currentValue === undefined ? [] : changes.resultsBoxesm.currentValue
                 this.recordId = changes.clientm.currentValue.recordId
                 this.OriginCallbackId = changes.clientm.currentValue.callbackId
                 this.creditAppliactionId_val = this.client.creditApplicationId
                 this.client.creditApplicationId > 0 ? this.getCreditApplicationDetails(this.client.recordId,this.client.creditApplicationId):null 
        }

    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onTabClick(event: MatTabChangeEvent){
        switch(event.tab.textLabel){
            case "Historia":{
                if(!this.recordHistory){
                    this.record.getRecordHistory(this.client.recordId)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(resp => {
                        this.recordHistory = <any> resp.body
                        this.recordHistory.sort(function(a,b){
                            let ac = new Date(a.contactDateTime).getTime()
                            let bc = new Date(b.contactDateTime).getTime()
                            return bc - ac
                        })
                        this.creditApplicationHistory = this.recordHistory
                        if(this.creditAppliactionId_val > 0){
                            this.recordHistory = this.creditApplicationHistory.filter(itm=>itm.creditApplicationId === this.creditAppliactionId_val)
                        }
                    })
                }
                break;
            }
            case "Dokumenty":{
                this.file.getRecordDocuments(this.client.recordId,this.creditAppliactionId_val)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp=>{
                    this.recordDocuments = <any> resp.body
                    this.recordDocuments.sort(function(a,b){
                        let ac = new Date(a.addDate).getTime()
                        let bc = new Date(b.addDate).getTime()
                        return bc - ac
                    })
                })
                break;
            }
        }
    }

    backToMainPage(){
        sessionStorage.removeItem("manual")
        this.router.navigate(['home'])
    }

    async handleFileInput(event){    
        this.fileErrors = []
        for(let i of event){
            if(i.size < 50000001){
                this.loadingBar = true
                const baseFile = await this.file.convertToBase(i)
                let ext = i.type
                let base64 = JSON.stringify(baseFile)
                base64.replace('"','')
                let sizeKb = parseInt(i.size)/1000
                let fileDetails = {
                    "RecordId": this.client.recordId,
                    "FileName": i.name,
                    "FileBase64": base64.replace('"data:' + i.type + ";base64,","").replace('"',''),
                    "FileSizeKb": parseInt(sizeKb.toFixed()),
                    "Extension": ext.replace("application/",""),
                    "FileFormat": i.type,
                    "CreditApplicationId":this.creditAppliactionId_val
                }
                this.file.uploadFile(fileDetails)
                    .pipe(
                    catchError((err: any) => {
                            this.communicationService.emitChange();
                            console.log(err)
                            this.snackBar.open(err.error, "X", {
                                duration: 10000,
                                panelClass: "bg-danger"
                            });
                            return empty()
                        }),
                        takeUntil(this.ngUnsubscribe)
                    )
                    .subscribe(resp =>{
                        this.recordDocuments.push(
                            {
                                "recordDocumentId":parseInt(resp.value),
                                "fileSizeKb":parseInt(sizeKb.toFixed()),
                                "fileName":i.name.replace(".xlsx",""),
                                "extension":ext.replace("application/",""),
                                "addedByUserId":99,
                                "addDate":new Date(),

                            })
                            this.recordDocuments.sort(function(a,b){
                                let ac = new Date(a.addDate).getTime()
                                let bc = new Date(b.addDate).getTime()
                                return bc - ac
                            })
                        this.loadingBar = false
                        this.snackBar.open("Ostatni dodany plik " + i.name, "X", {
                            duration: 10000,
                            panelClass: "bg-success"
                        });
                    });            

            } else {
                this.fileErrors.push({fileName:i.name})
                this.snackBar.open("Wybrany plik przekracza dopuszczalny limit w wysokości 50 MB", "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                });            
            }
        }
    }

    clearErrorFiles(){
        this.fileErrors = []
    }

    downloadFile(fileId){
        this.loadingBar = true
        this.file.downloadFile(fileId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp => {
            if(resp){
                this.file.getPhisicalFile(resp.body)
                this.loadingBar=false
            }
        })
    }

    getFileIcon(extension){
        switch(extension){
            case "pdf":{
                return this.faFilePdf
                break;
            }
            case "jpg":{
                return this.faFileImage
                break;
            }
            case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":{
                return this.faFileExcel
                break;
            }
            case "vnd.openxmlformats-officedocument.wordprocessingml.document":{
                return this.faWord
            }
            default:{
                return this.faFile
                break;                
            }
        }
    }

    openDrawer(eve){
        eve.type === "drawer"?this.drawer.open():this.drawer.close()
        eve.type === "finalResultDrawer" ? this.finalFormDrawer.open():this.finalFormDrawer.close()
        this.contactResultId = eve.value
    }

    closeDrawer(){
        //this.drawerFlag = false
        this.communicationService.emitDrawerForm(false)
    }

    closePanel(){
        this.communicationService.emitExpPanel(true)
    }

    applicationFormSubmit(){

        let creditApplicationDetails = {
            "RecordId": this.client.recordId,
            "ProductId": this.creditApplication.value.product,
            "RequestedCreditAmount": this.creditApplication.value.offerAmount,
            "RequestedCreditPeriodInMonths": this.creditApplication.value.offerPeriod,
            "BankId": this.creditApplication.value.bank,
            "BankOfferName": this.creditApplication.value.offerName,
            "ProductTypeId": this.creditApplication.value.productType,
            "ContactNotes": this.creditApplication.value.notes,
            "ContactResultId": this.contactResultId,
            "ProductDocumentsIDs":this.creditApplication.value.files           
        }
        this.record.saveProduct(creditApplicationDetails)
        .pipe(
           catchError((err: any) => {
                this.communicationService.emitChange();
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            this.creditApplication.reset()
            this.drawer.close()
            //this.closePanel()
            this.isManual = false
            this.router.navigate(['/home']);
            this.snackBar.open("Złożono wniosek o numerze: " + resp.value, "X", {
                duration: 10000,
                panelClass: "bg-success"
              });
        });
    }

    saveFinalResult(){
        let creditApplicationFinalStatus ={
            "CreditApplicationId": this.creditAppliactionId_val,
            "ClientName": this.finalResultForm.value.fullName,
            "BankName": this.finalResultForm.value.bankName,
            "BrokerName": this.finalResultForm.value.broker,
            "GrossValue": this.finalResultForm.value.grossAmount,
            "NettValue": this.finalResultForm.value.netAmount,
            "PricingTerms": this.finalResultForm.value.creditConditions,
            "DateReleased": this.finalResultForm.value.releasedDate !== ""? formatDate(this.finalResultForm.value.releasedDate,"yyyy-MM-dd","en_PL"):"",
            "CreditAgreementNumber": this.finalResultForm.value.invoiceValue,
            "Notes": "",
            "InvoiceValue": this.finalResultForm.value.fv,
            "HasInvoicePaid": Boolean(JSON.parse(this.finalResultForm.value.invoicePaid)),
            "RecordId": this.client.recordId,
            "ContactResultId": this.contactResultId,
            "ContactNotes": this.finalResultForm.value.notes
        }
        this.record.saveCreditAppRelease(creditApplicationFinalStatus)
        .pipe(
            catchError((err: any) => {
                 this.communicationService.emitChange();
                 console.log(err)
                 this.snackBar.open(err.error, "X", {
                     duration: 10000,
                     panelClass: "bg-danger"
                   });
                 return empty()
             }),
             takeUntil(this.ngUnsubscribe)
         )
         .subscribe(resp =>{
            this.finalResultForm.reset()
            this.finalFormDrawer.close()
            this.isManual = false
            this.router.navigate(['/home']);
            this.snackBar.open("Wniosek zakończony ", "X", {
                duration: 10000,
                panelClass: "bg-success"
              });
            this.communicationService.emitExpPanel(true)
         })
        
    }

    fillFormData(){
        this.finalResultForm.setValue({
            fullName:this.client.lastName + " " + this.client.firstName,
            //owner: '',
            bankName: this.creditApplicationArr.bank,
            broker: '',
            netAmount: '',
            grossAmount: '',
            creditConditions : '',
            fv:'',
            overCommision: '',
            releasedDate: '',
            invoicePaid: '',
            invoiceValue: '',
            notes: ''
        })
    }

    getCreditAppDDL(){
        if(this.productDDL === undefined && this.bankDDL === undefined && this.productTypeDDL === undefined){
            this.sharedServices.getCreditAppDDL()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp => {
                let dict = <any> resp.body
                this.bankDDL = dict.bank
                this.productDDL = dict.product
                this.productTypeDDL =dict.productType
            })
        }
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return a.value.localeCompare(b.value);
      }

    applicationFormValidators(){
        this.creditApplication.get("product").valueChanges
            .subscribe(val=>{
                if(val === "0"){
                    this.creditApplication.get("offerPeriod").setValidators([Validators.required,Validators.pattern("^[0-9]*$")])
                } else {
                    this.creditApplication.get("offerPeriod").setValidators(null)
                }

                this.creditApplication.get("offerPeriod").updateValueAndValidity()
            })
    }

    focusOnForm(){
        let el = document.getElementById("creditApplicationForm")
        el.scrollIntoView()

        this.file.getRecordDocuments(this.client.recordId,this.creditAppliactionId_val)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.recordDocuments = <any> resp.body
            this.recordDocuments.sort(function(a,b){
                let ac = new Date(a.addDate).getTime()
                let bc = new Date(b.addDate).getTime()
                return bc - ac
            })
        })        
    }

    modifyCheckBoxArr(event){
        let applicationFiles = <FormArray>this.creditApplication.get('files') as FormArray

        if(event.checked){
            applicationFiles.push(new FormControl(event.source.value))
        } else {
            const i = applicationFiles.controls.findIndex(x => x.value === event.source.value);
            applicationFiles.removeAt(i);            
        }
    }

    getCreditApplicationDetails(recordID,creditApplicationID){
        //this.creditApplicationArr
        this.record.getProductDetails(creditApplicationID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp=>{
                this.creditApplicationArr = <any> resp.body
            })
    }

    openDeleteDialog(recordDocumentId){
        const dialogRef = this.dialog.open(DeleteFileDialog, {
            width:"300px",
            data:{
                fileId:recordDocumentId
            }
        })

        dialogRef.afterClosed().subscribe(result=>{
            if(result === true){
                this.deleteFile(recordDocumentId)
            }
        })
    }

    openEditCredtiFromDialog(){
        let editCreditApplicationArr={
            "creditApplicationNr":this.creditApplicationArr.creditApplicationNr,
            "CreditApplicationId": this.creditAppliactionId_val,
            "ProductId": this.creditApplicationArr.productId,
            "RequestedCreditAmount": this.creditApplicationArr.requestedCreditAmount,
            "RequestedCreditPeriodInMonths": this.creditApplicationArr.requestedCreditPeriodInMonths,
            "BankName": this.creditApplicationArr.bankName
        }
        
        const dialogRef = this.dialog.open(EditCreditFormDialog,{
            data:editCreditApplicationArr
            
        })

        dialogRef.afterClosed().subscribe(result=>{
            if(result !== undefined){
                this.updateProduct(result)
            }
        })
    }

    deleteFile(recordDocumentId){
        this.file.deleteFile(recordDocumentId)
        .pipe(
           catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            this.recordDocuments.splice(this.recordDocuments.findIndex(itm=>itm.recordDocumentId === recordDocumentId),1)
            this.snackBar.open("Usunięto dokument", "X", {
                duration: 10000,
                panelClass: "bg-success"
              });
        })
    }

    unPinDocument(recordDocumentId){

        let docObj ={
            "CreditApplicationId": this.creditAppliactionId_val,
            "DocumentId": recordDocumentId           
        }
        this.file.unpinDocument(docObj)
        .pipe(
           catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            this.recordDocuments.splice(this.recordDocuments.findIndex(itm=>itm.recordDocumentId === recordDocumentId),1)
            this.snackBar.open("Odpięto dokument z wniosku", "X", {
                duration: 10000,
                panelClass: "bg-success"
              });
        })
    }

    updateProduct(data){
        this.record.updateProduct(data)
        .pipe(
           catchError((err: any) => {
                this.communicationService.emitChange();
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            this.snackBar.open("Zaktualizowano wniosek numer: " + data.creditApplicationNr, "X", {
                duration: 10000,
                panelClass: "bg-success"
            });
            this.creditApplicationArr.requestedCreditAmount = data.RequestedCreditAmount
            this.creditApplicationArr.requestedCreditPeriodInMonths = data.RequestedCreditPeriodInMonths
        })
    }

    changeHistoryType(event){
        if(event.checked){
            this.historyType = "wniosku"
            this.recordHistory = this.creditApplicationHistory.filter(itm=>itm.creditApplicationId === this.creditAppliactionId_val)            
        } else {
            this.historyType = "klienta"
            this.recordHistory = this.creditApplicationHistory
        }
    }

    hideCloseIcon(){
        var closeIcon = document.getElementById("closeProdTab")
        closeIcon.style.display = "none"
    }

    showCloseIcon(){
        var closeIcon = document.getElementById("closeProdTab")
        closeIcon.style.display = ""
    }
}

@Component({
    selector:'delete-file-dialog',
    templateUrl:'delete-file-dialog.html'
})

export class DeleteFileDialog {
    constructor(
        public dialogRef: MatDialogRef<DeleteFileDialog>,
        @Inject(MAT_DIALOG_DATA) public data:DialogData){}
     
    dialogQuestionResponse(decision){
        this.dialogRef.close(decision)
    }
}

@Component({
    selector:'edit-credit-form-dialog',
    templateUrl:'edit-credit-form-dialog.html'
})

export class EditCreditFormDialog {
    constructor(
        public dialogRef: MatDialogRef<DeleteFileDialog>,
        @Inject(MAT_DIALOG_DATA) public data:any){}
    
    updateProductDetails(flag){
        //this.dialogRef.close()
        if(flag){
            this.dialogRef.close(this.data)
        } else {
            this.dialogRef.close()
        }
    }

    inputKeyUp(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.key);
    
        if (!pattern.test(inputChar)) {
          event.preventDefault();
        }
    }
}

