import {Component, ViewEncapsulation, ViewChild, Output,EventEmitter} from '@angular/core'
import {MatDatepicker, ThemePalette} from '@angular/material'
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';


@Component({
    selector:'calendar-result',
    templateUrl: './calendar-result.component.html',
    styles:[`
        .ng-star-inserted td {
            padding-top:0rem;
            padding-bottom:0rem;
            border-top:none;
        }

        .spacer {
            padding-top:1rem!important;
        }
    `],
    encapsulation:ViewEncapsulation.None
})

export class CalendarResultComponent {
    color:ThemePalette = 'accent'
    minDate=new Date()
    calendarInput:any
    calendarOpened:boolean = false
    
    @ViewChild('dpcr',{static:true}) picker: NgxMatDatetimePicker<any>;
    @Output()
    calendarEvent: EventEmitter<any> = new EventEmitter<any>()
    @Output()
    focusFlag: EventEmitter<any> = new EventEmitter<any>()

    ngOnInit(){
        this.calendarEvent.emit(null)
        
    }

    ngAfterViewInit(){
        setTimeout(()=>{
            this.picker.open()
            this.focusFlag.emit(true)
        },100)
    }

    EndDateChange(ev){
        this.calendarEvent.emit(ev.value)
        this.focusFlag.emit(false)
    }

}