import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

export interface GUIDresp {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    expirationDate: string;    
}

@Injectable()

export class ValidateGuidService {
    baseUrl: string = environment.backend.baseURL
    constructor(private http:HttpClient){}

    validateGUID(GUID){
        return this.http.get<GUIDresp>(`${this.baseUrl}` + "/Account/validateGuid?guid=" + GUID)
    }

    passService(UserId: string, Password: string){
        let serviceParams = { UserId: UserId, Password: Password}
    
        return this.http.put(`${this.baseUrl}` + '/Account/activate', serviceParams, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }     
}