import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn:'root'})

export class GetUserCallback {
    baseUrl = environment.backend.baseURL
    constructor(private http:HttpClient){}
    
    getCallbackList(){
        return this.http.get(`${this.baseUrl}` + '/Callbacks/getActiveCallbacksForUser',{observe:'response',withCredentials:true})
    }
}