import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class AccountService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    validateTwoFaCode(form){
        return this.http.post(`${this.baseUrl}` + '/Account/validateTwoFaCode', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    twoFAOff(form){
        return this.http.put(`${this.baseUrl}` + '/Account/twoFAOff', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    getQRCode(){
        return this.http.get(`${this.baseUrl}` + '/Account/getQRCode',{observe:'response',withCredentials:true})
    }

    twoFAStatus(){
        return this.http.get(`${this.baseUrl}` + '/Account/twoFAStatus',{observe:'response',withCredentials:true})
    }

}