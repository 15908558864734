import { Component } from '@angular/core'

@Component({
  templateUrl:'./404.component.html',
  styles: [`
    .errorMessage { 
      margin-top:150px; 
      font-size: 170px;
      text-align: center;
      text-shadow:2px 2px 2px black; 
    }`]
})
export class Error404Component{
  constructor() {

  }

}