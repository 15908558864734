import {Component, ViewEncapsulation, ViewChild, ElementRef, OnDestroy} from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileService } from '../../shared/file.service';
import { catchError, takeUntil } from '../../../../node_modules/rxjs/operators';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import { empty, Subject } from '../../../../node_modules/rxjs';
import { ngIfAnimation, ngIfAnimation3 } from '../../animations/animations';
import { HttpEventType, HttpResponse } from '../../../../node_modules/@angular/common/http';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';
import { KeyValue } from '../../../../node_modules/@angular/common';

@Component({
    selector: 'upload-base',
    templateUrl: './upload-base.component.html',
    styles:[`
        /*.mat-stepper-horizontal {background-color:#3a3f44}*/
        .mat-stepper-horizontal {
            background-color:#212121;
            color:#bababa;
        }
        .mat-step-icon-selected {
            background-color:#69f0ae!important;
            color: #212121!important;
            font-weight: bold;
        }
    `],
    animations:[
        ngIfAnimation,
    ],
    encapsulation:ViewEncapsulation.None
})

export class UploadBaseComponent implements OnDestroy{
    @ViewChild("stepper",{static:true}) stepper
    uploadBaseFirstStep: FormGroup;
    uploadBaseSecondStep: FormGroup;
    uploadBaseThirdStep: FormGroup;
    POSTStatus:any
    fileName:string
    newBase:boolean = true
    percentageDone:number = 0
    fileNameDesc:string
    campaigns:any
    teamsDDL:any = []
    userDDL:any = []
    assignToUserForm:boolean = false
    isChecked:boolean = false
    private formData:FormData = new FormData();
    private ngUnsubscribe = new Subject()
  
    constructor(
        private _formBuilder: FormBuilder,
        private file:FileService,
        private snackBar:MatSnackBar,
        private sharedServices: SharedServicesService
    ) {}
  
    ngOnInit() {
      this.uploadBaseFirstStep = this._formBuilder.group({
        baseNameInput: ['',Validators.required],
        baseNameDDL: ['']
      });
      this.uploadBaseSecondStep = this._formBuilder.group({
        baseFile: ['', Validators.required]
      });
      this.uploadBaseThirdStep = this._formBuilder.group({
        assignToUserTeams:'',
        assignToUser:[]
      })
      
    }

    getUserDDL(event){
        this.sharedServices.getDDLTeamUsers(event.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.userDDL = <any>resp.body
        })      
    }

    assignUserId(event){
        this.formData.delete('assignUser')
        this.formData.append('assignUser',event.value)
    }

    assignToUser(event){
        switch(event.checked){
            case true:{
                this.isChecked = true
                this.assignToUserForm = event.checked
                this.uploadBaseThirdStep.controls['assignToUser'].setValidators([Validators.required])
                this.uploadBaseThirdStep.controls['assignToUser'].updateValueAndValidity()
                this.sharedServices.getDDLTeams()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp=>{
                    this.teamsDDL = <any>resp.body
                }) 
                break;
            }
            case false:{
                this.isChecked = false
                this.formData.delete('assignUser')
                this.formData.append('assignUser','0')
                this.assignToUserForm = event.checked
                this.uploadBaseThirdStep.controls['assignToUser'].clearValidators()
                this.uploadBaseThirdStep.controls['assignToUser'].updateValueAndValidity()
                break;
            }
        }
    }

    changeValidation(event){
        switch (event.value){
            case "0":{
                    this.uploadBaseFirstStep.controls["baseNameInput"].setValidators([Validators.required])
                    this.uploadBaseFirstStep.controls["baseNameDDL"].clearValidators()
                    this.uploadBaseFirstStep.controls["baseNameInput"].updateValueAndValidity()
                    this.uploadBaseFirstStep.controls["baseNameDDL"].updateValueAndValidity()
                    this.uploadBaseFirstStep.controls["baseNameDDL"].reset()
                break;
            }
            case "1":{
                    this.getCampaignsList()
                    this.uploadBaseFirstStep.controls["baseNameInput"].clearValidators()
                    this.uploadBaseFirstStep.controls["baseNameDDL"].setValidators([Validators.required])
                    this.uploadBaseFirstStep.controls["baseNameInput"].updateValueAndValidity()
                    this.uploadBaseFirstStep.controls["baseNameDDL"].updateValueAndValidity()
                    this.uploadBaseFirstStep.controls["baseNameInput"].reset()
                break;
            }
        }
    }

    getCampaignsList(){
        this.sharedServices.getDDLCampaignList()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let arr = <any> resp.body
            this.campaigns = arr.campaigns           
        })
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return a.value.localeCompare(b.value);
    }

    handleFileInput(event){
        let fileList: FileList = event.target.files;
        this.fileNameDesc = fileList[0].name
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.fileName = "Nazwa pliku: " + file.name
            this.formData.append('uploadFile', file, file.name)
            this.formData.append('campaignId',this.uploadBaseFirstStep.value.baseNameDDL)
            this.formData.append('campaignName',this.uploadBaseFirstStep.value.baseNameInput)
            this.formData.append('assignUser','0')
        }
    }

    submitForm(){
        if(this.uploadBaseThirdStep.status === 'VALID'){
            this.file.uploadBaseFile(this.formData)
                .pipe(
                catchError((err: any) => {
                        this.POSTStatus = {error:err.error}
                        console.log(err)
                        this.snackBar.open(err.error, "X", {
                            duration: 10000,
                            panelClass: "bg-danger"
                        });
                        return empty()
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe(resp =>{
                    if(resp.type === HttpEventType.UploadProgress){
                        this.percentageDone = Math.round(100 * resp.loaded / resp.total)
                    } else if(resp instanceof HttpResponse){
                        let respObj = <any> resp.body
                        let message = respObj.message
                        let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                        let dur = respObj.isSuccess? 3000:10000
                        if(respObj.isSuccess){
                            setTimeout(()=>{
                                this.stepper.reset()
                                this.fileName = null
                                this.uploadBaseThirdStep.reset()
                                this.percentageDone = 0
                                this.formData = new FormData
                                this.isChecked = false
                                this.assignToUserForm = false
                            },1000)
                            //this.stepper.reset()
                            //this.stepper.markAsUntouched()
                            //this.fileName = null
                        }
                        this.snackBar.open(message, "X", {
                            duration: dur,
                            panelClass: messageClass
                        });
                    }
            });
        }         
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    markTouched(){
        this.uploadBaseThirdStep.markAllAsTouched()
    }

    markUnTouched(){
        this.uploadBaseThirdStep.markAsUntouched()
    }

}