import { Component, OnDestroy, ViewChild, Inject, ChangeDetectorRef } from "@angular/core";
import { takeUntil, catchError } from '../../../../node_modules/rxjs/operators';
import { DocTreeService } from '../../services/doctree/doctree.service';
import { MatSnackBar, MatDialog, MatMenuTrigger, MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { CommunicationService } from '../../services/communication/communication.service';
import { Subject, empty } from '../../../../node_modules/rxjs';
import { FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { RecursiveSearchPipe } from '../../pipes/recursive.pipe';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
    selector:'new-doc-tree',
    templateUrl:'./new-doc-tree.component.html'
})

export class NewDocTreeComponent implements OnDestroy{
    mainNode = []
    childNode = []
    childNode2 = []
    temp_arr
    lastNode = []
    previousLevel:any = null
    mod:any
    nextLevel:any
    isFirstNode:boolean = false
    breadCrump:any = []
    private ngUnsubscribe = new Subject()

    constructor(
        public router:Router,
        public doctree:DocTreeService,
        public snackBar:MatSnackBar,
        public dialog:MatDialog,
        public communicationService:CommunicationService,
        public recursive:RecursiveSearchPipe,
        private changeDetectorRef: ChangeDetectorRef
      ){
        this.communicationService.refreshRoot.subscribe(val=>{
          if(val){
            this.getRoot()
          }
        })
      }
    
    @ViewChild(MatMenuTrigger,{static: true})
    contextMenu: MatMenuTrigger;
    
    contextMenuPosition = { x: '0px', y: '0px' };
    
    onContextMenu(event: MouseEvent, item) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'item': item };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    ngOnInit(){
        this.getRoot()
    }

    getRoot(){
        this.doctree.getRoot()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(resp=>{
            this.resetTree()
            let response = <any> resp.body
            this.mainNode = response.childItems
            this.changeDetectorRef.detectChanges()
        })
    }

    addRoot(){
        let item = {childID:14}
        this.onContextMenuAction(item,0)
    }

    resetTree(){
        //this.mainNode = []
        this.childNode = []
        this.isFirstNode = false
        this.lastNode = []
        this.breadCrump = []
        this.childNode2 = []
    }

    createChild(id,val){
        this.lastNode = []
        this.isFirstNode = true

        if(val === 0){
            this.isFirstNode = false
            this.temp_arr = undefined
            this.childNode = []
            this.lastNode = []
            this.breadCrump = []
        }
        this.nextLevel = this.recursive.transform(1,this.mainNode,id,'childID','childItems',0)[0]
        let exist = this.breadCrump
        if(exist.filter(x=>x.childName === this.nextLevel.childName).length === 0){
            let obj = {
                childID: this.nextLevel.childID,
                childName: this.nextLevel.childName,
            }
            this.breadCrump.push(obj)
        }
        let flag = 0
        let temp_obj = this.nextLevel.childItems
        let temp_childArr=[]
        this.childNode2 = []
        for(var i=0;i<temp_obj.length;i++){
            this.childNode2.push(temp_obj[i])
            if(temp_obj[i].childItems.length > 0){
                flag = flag + 1
                temp_childArr.push(temp_obj[i])
            } else {
                this.lastNode.push(temp_obj[i])
            }
        }
        
        if(temp_childArr.length >0){

        }
        this.childNode = flag === 0 ? this.temp_arr : temp_childArr
    }

    backOneLevel(){
        this.breadCrump.pop()
        if(this.breadCrump.length > 0){
            let elID = this.breadCrump[this.breadCrump.length - 1]
            this.createChild(elID.childID,1)
        } else {
            this.resetTree()
        }
        
    }

    onContextMenuAction(item,id){
        switch(id){
            case 0:{
                this.openDialog(item.childID,null,0)
                break;
            }
            case 1:{
                this.openDialog(item.childID,item.childName,1)
                break;
            }
            case 2:{
                this.deleteItems(item.childID)
                break;
            }
        }
    }

    deleteItems(id){
        this.doctree.deleteTreeElement(id)
        .pipe(
        catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            let respObj = JSON.parse(resp.body)
            let message = respObj.message
            let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
            if(respObj.isSuccess){
                this.contextMenu.closeMenu()
                this.getRoot()
            }
            this.snackBar.open(message, "X", {
                duration: respObj.isSuccess? 3000:10000,
                panelClass: messageClass
            });
            
        }) 
    }

    addItem(val,id){
        let form = {
            "ChildName": val,
            "ParentId": id       
        }
        this.doctree.addItem(form)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                    });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
                if(respObj.isSuccess){
                  this.communicationService.refreshRoot.emit(true)
                }
        });
    }

    editItem(val,id){
        let form = {
            "ChildId": id,
            "NewValue": val       
        }
        this.doctree.editItem(form)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                    });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
                 let respObj = JSON.parse(resp.body)
                 let message = respObj.message
                 let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                 this.snackBar.open(message, "X", {
                     duration: 3000,
                     panelClass: messageClass
                 });
                 if(respObj.isSuccess){
                   this.communicationService.refreshRoot.emit(true)
                 }
        });
    }

    openDialog(id,value,type){
        const dialogRef = this.dialog.open(ContextMenuDialog2,{
            width:'40rem',
            data:{
                childID:id,
                childName:value,
                type:type
            }
        })
        dialogRef.afterClosed().subscribe(result=>{
            this.contextMenu.closeMenu()
            if(result.type === 0){
                this.addItem(result.value,result.id)
            } else {
                this.editItem(result.value,result.id)
            }
            
        })
        
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}

@Component({
    selector:'context-menu-dialog',
    templateUrl:'./menu-panel/context-menu-dialog.component.html'
  })
  
  export class ContextMenuDialog2 implements OnDestroy{
    descValue = new FormControl('',Validators.required)
    headerText:string
    private ngUnsubscribe = new Subject()
  
    constructor(
        public dialogRef: MatDialogRef<ContextMenuDialog2>,
        @Inject(MAT_DIALOG_DATA) public data,
        private doctree:DocTreeService,
        private snackBar:MatSnackBar,
        private communicationService:CommunicationService
    ) {}

    ngOnInit(){
        this.descValue.setValue(this.data.childName)
        this.headerText = this.data.type === 0 ? "Podaj nazwę lub wartość nowego elementu" : "Zmień nazwę elementu"
    }
  
    addItemRef(){
        let dialogData = {
            value:this.descValue.value,
            id:this.data.childID,
            type:this.data.type
        }
        this.dialogRef.close(dialogData)
    }

    editItemRef(){
        let dialogData = {
            value:this.descValue.value,
            id:this.data.childID,
            type:this.data.type
        }
        this.dialogRef.close(dialogData)
    }
  
    closeDialog(){
      this.dialogRef.close()
    }
  
    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
  
  }